<template>
  <div class="mainContent" v-loading="contentLoading">
    <div class="downBtn" @click="exportPdf">下载</div>
    <div id="printContent">
      <div class="first" style="page-break-after: always">
        <img src="../../../assets/week.png"/>
        <div class="elevator">
          <div style="display: flex">
            <div style="width: 70px">使用单位：</div>
            <div style="flex: 1">{{ model.useUnitName }}</div>
          </div>
          <div style="display: flex">
            <div style="width: 70px">维保企业：</div>
            <div style="flex: 1">{{ model.maintComName }}</div>
          </div>
        </div>
        <div class="bottom">该报告仅供参考</div>
      </div>
      <div style="margin-top: 10px">
        <div class="only" style="text-align: right;margin: 0 10px 10px 0;">
          <el-checkbox v-model="checked" @change="changeHandle">仅显示异常项</el-checkbox>
        </div>
        <table cellspacing="0" cellpadding="0">
          <tr>
            <td class="tableItem">排查周期</td>
            <td colspan="3">
              {{ model.startDate + ' 至 ' + model.endDate }}
            </td>
          </tr>
          <tr>
            <td class="tableItem">记录时间</td>
            <td>{{ model.createTime }}</td>
            <td class="tableItem">安全总监</td>
            <td>{{ model.safetyDirector }}</td>
          </tr>
          <tr>
            <td class="tableItem">设备代码</td>
            <td>{{ model.registerNo }}</td>
            <td class="tableItem">电梯名称</td>
            <td>{{ model.elevatorName }}</td>
          </tr>
        </table>
      </div>
      <div class="reportItems" v-for="(item, index) in model.results" :key="index">
        <div class="itemsTitle">
          <img v-if="item.itemType == '运行统计'" src="../../../assets/10.png"/>
          <img v-else-if="item.itemType == '故障'" src="../../../assets/5.png"/>
          <img v-else-if="item.itemType == '设备'" src="../../../assets/8.png"/>
          <img v-else-if="item.itemType == '环境'" src="../../../assets/6.png"/>
          <img v-else-if="item.itemType == '人员'" src="../../../assets/7.png"/>
          <img v-else-if="item.itemType == '管理'" src="../../../assets/11.png"/>
          <img v-else-if="item.itemType == '告警'" src="../../../assets/9.png"/>
          <img v-else src="../../../assets/14.png"/>
          <span>周排查类别：{{ item.itemType }}</span>
        </div>
        <table cellspacing="0" cellpadding="0" v-if="item.itemType !== '故障' && item.itemType !== '告警'">
          <tr>
            <td class="tableItem">周排查项目</td>
            <td class="tableItem" style="width: 100px; text-align: center">排查结果</td>
            <td class="tableItem" style="width: 240px">处理结果</td>
          </tr>
          <tr v-for="(each, eachIndex) in item.items" :key="eachIndex">
            <td :class="[each.mark?'red':'']">{{ each.itemName }}</td>
            <td style="text-align: center">{{ each.itemValue }}</td>
            <td v-if="each.mark">{{ each.handleResult?each.handleResult:"未填写" }}</td>
            <td v-else>{{ each.handleResult }}</td>
          </tr>
        </table>
        <!-- 告警 -->
        <div v-else-if="item.itemType == '告警'">
          <div class="faults" v-for="(each, eachIndex) in item.items" :key="eachIndex">
            <div :class="['faultsType',each.mark?'red':'']">{{ each.itemName }}</div>
            <div class="faultsRecords" v-if="each.itemName == '困人记录'">
              <div v-if="model.trappedRecords.length">
                <table cellspacing="0" cellpadding="0" style="width: 100%">
                  <tr>
                    <td style="width: 120px; text-align: center">告警时间</td>
                    <td style="width: 80px; text-align: center">状态</td>
                    <td>持续时间</td>
                  </tr>
                  <tr v-for="(i, faultIndex) in model.trappedRecords" :key="faultIndex">
                    <td style="text-align: center">{{ i.alarmTime }}</td>
                    <td style="text-align: center">
                      <span v-if="i.recordState == 0">报警</span>
                      <span v-else-if="i.recordState == 1">接警</span>
                      <span v-else-if="i.recordState == 2">到场</span>
                      <span v-else-if="i.recordState == 3">完工</span>
                      <span v-else-if="i.recordState == 4">确认</span>
                      <span v-else-if="i.recordState == 5">撤销</span>
                      <span v-else-if="i.recordState == 8">自动确认</span>
                    </td>
                    <td>{{diffTime(i.finishTime,i.alarmTime)}}</td>
                  </tr>
                </table>
              </div>
              <div v-else class="noRecords">未见困人</div>
            </div>
            <div class="faultsRecords" v-if="each.itemName == '停梯记录'">
              <div v-if="model.stopRecords.length">
                <table cellspacing="0" cellpadding="0" style="width: 100%">
                  <tr>
                    <td style="width: 120px; text-align: center">告警时间</td>
                    <td style="width: 80px; text-align: center">状态</td>
                    <td>持续时间</td>
                  </tr>
                  <tr v-for="(i, faultIndex) in model.stopRecords" :key="faultIndex">
                    <td style="text-align: center">{{ i.alarmTime }}</td>
                    <td style="text-align: center">
                      <span v-if="i.recordState == 0">报警</span>
                      <span v-else-if="i.recordState == 1">接警</span>
                      <span v-else-if="i.recordState == 2">到场</span>
                      <span v-else-if="i.recordState == 3">完工</span>
                      <span v-else-if="i.recordState == 4">确认</span>
                      <span v-else-if="i.recordState == 5">撤销</span>
                      <span v-else-if="i.recordState == 8">自动确认</span>
                    </td>
                    <td>{{diffTime(i.finishTime,i.alarmTime)}}</td>
                  </tr>
                </table>
              </div>
              <div v-else class="noRecords">未见停梯</div>
            </div>
          </div>
        </div>
        <!-- 故障 -->
        <div v-else-if="item.itemType == '故障'">
          <div class="faults">
            <div :class="['faultsType',model.faultRecords.length?'red':'']">故障记录</div>
            <div class="faultsRecords" v-if="model.faultRecords.length">
              <table cellspacing="0" cellpadding="0" style="width: 100%">
                <tr>
                  <td style="width: 120px; text-align: center">故障时间</td>
                  <td style="width: 80px; text-align: center">故障代码</td>
                  <td>故障描述</td>
                </tr>
                <tr v-for="(i, faultIndex) in model.faultRecords" :key="faultIndex">
                  <td style="text-align: center">{{ i.faultTime }}</td>
                  <td style="text-align: center">{{ i.faultCode }}</td>
                  <td>{{ i.faultDesc }}</td>
                </tr>
              </table>
            </div>
            <div v-else class="noRecords">未见故障</div>
          </div>
        </div>
      </div>

      <!-- 固定项 -->
      <div class="reportItems">
        <div class="itemsTitle">
          <img src="../../../assets/13.png"/>
          <span>上周安全风险隐患问题整改核实情况</span>
        </div>
        <div class="itemsContent">{{ model.risk ? model.risk : "未填写" }}</div>
        <div class="itemsTitle">
          <img src="../../../assets/15.png"/>
          <span>本周主要安全风险隐患和整改情况</span>
        </div>
        <div class="itemsContent">{{ model.rectification ? model.rectification : "未填写" }}</div>
        <div class="itemsTitle">
          <img src="../../../assets/14.png"/>
          <span>本周安全管理情况评价</span>
        </div>
        <div class="itemsContent">{{ model.safetyManagementEvaluation ? model.safetyManagementEvaluation : "未填写" }}</div>
        <div class="itemsTitle">
          <img src="../../../assets/12.png"/>
          <span>下周工作重点</span>
        </div>
        <div class="itemsContent">{{ model.workFocus ? model.workFocus : "未填写" }}</div>
        <div class="itemsTitle">
          <img src="../../../assets/1.png"/>
          <span>安全管理员意见</span>
        </div>
        <div class="itemsContent">{{ model.safetyOfficerOpinion ? model.safetyOfficerOpinion : "未填写" }}</div>
        <div class="itemsTitle">
          <img src="../../../assets/2.png"/>
          <span>安全总监意见</span>
        </div>
        <div class="itemsContent">{{ model.safetyDirectorOpinion ? model.safetyDirectorOpinion : "未填写" }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2Canvas from "html2canvas";
export default {
  data() {
    return {
      model: {
        results: [],
        faultRecords: [],
        trappedRecords: [],
        stopRecords: [],
      },
      contentLoading: false,
      originResults:[],
      checked:false
    };
  },

  computed: {
    getItemValue() {
      return function (itemName) {
        if (this.model.results.length) {
          let result = this.model.results.find((item) => item.itemName === itemName);
          if (result) {
            return result.itemValue;
          }
        }
      };
    },

    getHandleResult() {
      return function (itemName) {
        if (this.model.results.length) {
          let result = this.model.results.find((item) => item.itemName === itemName);
          if (result) {
            return result.handleResult;
          }
        }
      };
    },

    diffTime() {
      return function (finishTime,alarmTime) {
        let diffSeconds;
        if(finishTime) {
          diffSeconds = (this.$moment(finishTime).valueOf() - this.$moment(alarmTime).valueOf()) / 1000;
        } else {
          diffSeconds = (this.$moment().valueOf() - this.$moment(alarmTime).valueOf()) / 1000;
        }
        let duration = this.$moment.duration(diffSeconds,"seconds");
          let days = duration.days()?duration.days() + "天":"";
          let hours = duration.hours()?duration.hours() + "小时":"";
          let minutes = duration.minutes()?duration.minutes() + "分":"";
          let seconds = duration.seconds()?duration.seconds() + "秒":"";
          return days+ hours  + minutes  + seconds ;
      };
    }
  },

  mounted() {
    this.getData(this.$route.params.id);
  },

  methods: {
    changeHandle(checked) {
      if(checked) {
        this.classifyHandle(this.originResults.filter(item => item.mark));
      } else {
        this.classifyHandle(this.originResults);
      }
    },
    getData(id) {
      this.contentLoading = true;
      this.$http
        .get(`periodicInspectionBill/${id}`)
        .then((res) => {
          this.contentLoading = false;
          this.model = res.data;
          let list = ["未按有关法律法规、安全技术规范或合同约定进行维护保养","配件更换记录","未按规定进行定期检验、自行检测"];
          let otherList = ["电瓶车识别","长时间挡门次数"];
          this.model.results.forEach(item => {
            if(item.itemValue) {
              if(item.itemValue == "异常") {
                item.mark = true;
              }

              if(item.itemValue == "有" && list.indexOf(item.itemName) == -1) {
                item.mark = true;
              }

              if(otherList.indexOf(item.itemName) !== -1) {
                let newValue = item.itemValue.substring(0,item.itemValue.length - 1);
                if(newValue > 0) {
                  item.mark = true;
                }
              }

              if(item.itemValue.includes("分钟") && item.itemName !== "本周检修持续时间") {
                let newValue = item.itemValue.substring(0,item.itemValue.length - 2);
                if(newValue > 0) {
                  item.mark = true;
                }
              }
            }else {
              item.itemValue = "未填写";
            }

            if(item.itemType == "故障" && this.model.faultRecords.length) {
              item.mark = true;
            }

            if(item.itemName == "困人记录" && this.model.trappedRecords.length) {
              item.mark = true;
            }

            if(item.itemName == "停梯记录" && this.model.stopRecords.length) {
              item.mark = true;
            }
          });
          this.originResults = this.model.results;
          this.classifyHandle(this.model.results);
        })
        .catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
    },

    // 将获取到的项目按照类别分类
    classifyHandle(arr) {
      let newArr = [];
      // 过滤掉隐藏的项目 及 rowIndex = -1
      let tempArr = arr.filter((item) => item.rowIndex !== -1);
      tempArr.forEach((item) => {
        const parent = newArr.find((c) => c.itemType === item.itemType);
        if (parent) {
          parent.items.push(item);
        } else {
          const obj = {
            itemType: item.itemType,
            items: [item],
          };
          newArr.push(obj);
        }
      });

      this.model.results = newArr;
    },

    // 导出PDF
    exportPdf() {
      var that = this;
      html2Canvas(document.getElementById("printContent"), { logging: false }).then(function (canvas) {
        let pdf = new jsPDF("p", "mm", "a4");
        var ctx = canvas.getContext("2d");
        let a4w = 200,
          a4h = 285;
        let imgHeight = Math.floor((a4h * canvas.width) / a4w);
        let renderedHeight = 0;
        while (renderedHeight < canvas.height) {
          var page = document.createElement("canvas");
          page.width = canvas.width;
          page.height = Math.min(imgHeight, canvas.height - renderedHeight);
          page
            .getContext("2d")
            .putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
          pdf.addImage(page.toDataURL("image/jpeg", 1.0), "JPEG", 5, 6, a4w, Math.min(a4h, (a4w * page.height) / page.width));
          renderedHeight += imgHeight;
          if (renderedHeight < canvas.height) {
            pdf.addPage();
          }
        }
        pdf.save(`${that.model.elevatorNO}-周排查报告-电梯云${that.$moment().format("YYYYMMDD")}.pdf`);
      });
    },
  },
};
</script>

<style lang="scss">
@media print {
  .downBtn,.only {
    display: none;
  }
}
.red {
  color: red;
}
.mainContent {
  padding: 5px;
  width: 210mm;
  margin: 0 auto;
  position: relative;
  font-size: 12px;
  .downBtn {
    width: 80px;
    height: 30px;
    position: absolute;
    top: 30px;
    right: 56px;
    border: 1px solid #014099;
    line-height: 30px;
    text-align: center;
    color: #014099;
    cursor: pointer;
  }
  .first {
    .elevator {
      width: 310px;
      position: absolute;
      top: 180px;
      right: 56px;
      font-size: 14px;
      color: #014099;
      div {
        margin-bottom: 5px;
      }
    }
    .bottom {
      position: absolute;
      top: 290mm;
      right: 56px;
      font-size: 14px;
      color: #014099;
    }
    img {
      height: 297mm;
    }
  }

  table {
    width: 100%;
    td {
      border: 1px solid #014099;
      border-right: none;
      border-bottom: none;
      padding: 10px;
      &:last-child {
        border-right: 1px solid #014099;
      }
    }
    tr {
      &:last-child td {
        border-bottom: 1px solid #014099;
      }
    }
    .tableItem {
      background-color: #f1f7ff;
    }
    &:first-child {
      .tableItem {
        width: 80px;
        text-align: center;
      }
    }
  }

  .reportItems {
    .itemsTitle {
      height: 40px;
      display: flex;
      align-items: center;
      img {
        width: 24px;
        margin-right: 5px;
      }
    }
    .itemsContent {
      border: 1px solid #014099;
      padding: 10px;
    }
    .faults {
      display: flex;
      border-bottom: none;
      .faultsType {
        width: 80px;
        background-color: #f1f7ff;
        border-top: 1px solid #014099;
        border-left: 1px solid #014099;
        border-bottom: 1px solid #014099;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .faultsRecords {
        flex: 1;
      }
      .noRecords {
        flex: 1;
        border: 1px solid #014099;
        padding: 10px;
      }
      &:nth-child(2) {
        margin-top: 10px;
      }
    }
  }
}
</style>
